import React from "react";
import styled from "styled-components";

import { SanityCtaBanner } from "@graphql-types";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, H3 } from "@util/standard";
import { Button } from ".";
import BlocksContent from "./blocksContent";
import Cta from "./cta";

interface Props {
  data: SanityCtaBanner;
}

const TextContainer = styled(Container)`
  width: 100%;
  justify-content: center;
  h1,
  h2,
  h3,
  h4,
  p {
    color: ${colors.darkerGreen};
  }
`;
function CtaBanner({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isLaptopWidth } = useCheckScreenWidth();

  return (
    <Container width="98%" margin="0 auto" backgroundRGBColor={colorsRGB.darkerGreen(0.05)}>
      <Container width="75%" margin="60px auto" flexDirection={isLaptopWidth ? "row" : "column"}>
        <TextContainer>
          <BlocksContent blocks={data.bannerText?._rawColumnContent} />
        </TextContainer>
        <Container width="37%" tabletWidth="100%" height="50%" margin="auto" justifyContent="center" mobileJustifyContent="flex-start">
          {data.ctas?.map(cta => {
            if (cta == null) {
              return null;
            }
            return <Cta whiteBackground data={cta} key={cta._key} />;
          })}
        </Container>
      </Container>
    </Container>
  );
}

export default CtaBanner;

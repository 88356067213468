import React from "react";
import styled from "styled-components";

import { SanityColumnContent } from "@graphql-types";
import { Container } from "@util/standard";
import { Color } from "@util/types";
import { BlocksContent, Image } from ".";
import Cta from "./cta";
import { colors, fontWeights, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityColumnContent;
  boldHeaders?: boolean;
}

const TextContainer = styled(Container)`
  width: 68%;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  min-height: 375px;
  h2,
  h3 {
    font-weight: ${fontWeights.normal};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    justify-content: center;
    margin: auto;
    min-height: 0px;
    margin: 8% auto;
    h2 {
      font-size: 22px;
      line-height: 26px;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 16% auto;
  }
`;

function ColumnContent({ data, boldHeaders }: Props) {
  if (data == null) {
    return null;
  }
  return (
    <Container
      width="98%"
      margin="auto auto 15px auto"
      flexDirection={data.reverse ? "row-reverse" : "row"}
      isMobileColumn
      tabletFlexDirection={data.mobileReverse ? "column-reverse" : "column"}
    >
      <Container
        maxHeight="698px"
        height="690px"
        tabletHeight="375px"
        width="49.5%"
        tabletWidth="100%"
        margin={data.reverse ? "0 0 0 0.5%" : "0 0.5% 0 0"}
        tabletMargin="0"
      >
        <Image objectFit="cover" minHeight="375px" width="100%" image={data.columnImage?.asset?.gatsbyImageData} />
      </Container>
      <Container
        width="49.5%"
        tabletWidth="100%"
        margin={data.reverse ? "0 0.5% 0 0" : "0 0 0 0.5%"}
        tabletMargin="0"
        backgroundColor={data.backgroundColor as Color}
      >
        <TextContainer>
          <BlocksContent
            blocks={data.columnText?._rawColumnContent}
            whiteText={data.backgroundColor !== "white"}
            boldHeaders={boldHeaders}
          />
          <Container margin="40px 0px 0px 0px" flexWrap="wrap">
            {data.ctas?.map(cta => {
              if (cta == null) {
                return null;
              }

              if (cta.ctaStyle === "block" || cta.ctaStyle === "bordered") {
                return (
                  <Container width="100%" key={cta._key}>
                    <Cta whiteBackground={data.backgroundColor === "white"} data={cta} />
                  </Container>
                );
              }
              return (
                <Container margin="30px 0 0 0">
                  <Cta data={cta} padding="0px 30px 0px 0" key={cta._key} />
                </Container>
              );
            })}
          </Container>
        </TextContainer>
      </Container>
    </Container>
  );
}

export default ColumnContent;

import React from "react";
import styled from "styled-components";

import { SanityLearnCategoryPreview } from "@graphql-types";
import { gradients } from "@util/constants";
import { Container, Gradient, H2, LinkContainer, P } from "@util/standard";
import { Button, Image } from ".";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityLearnCategoryPreview;
}

const StyledContainer = styled(Container)`
  .gatsby-image-wrapper > img {
    transform: scale(1);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .gatsby-image-wrapper > img {
      transform: scale(1.1);
      transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }
`;

function LearnCategoryPreview({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isMobileWidth, isLaptopWidth } = useCheckScreenWidth();

  return (
    <Container margin="0 0 15px 0">
      <Container width="98%" margin="15px auto 0 auto" tabletMargin="4% auto 0 auto" isMobileColumn>
        {data.learnCategoryRefs?.map((category, index) => {
          if (category == null || data.learnCategoryRefs == null) {
            return null;
          }

          const isLast = index === data.learnCategoryRefs?.length - 1;

          return (
            <LinkContainer
              width="100%"
              margin={isLast ? "0" : "0 15px 0 0"}
              tabletMargin="0 auto 4% auto"
              to={`/learn/${category.slug?.current}`}
              tabletWidth="92%"
            >
              <StyledContainer position="relative" key={category._id}>
                <Container
                  flexDirection="column"
                  width="72%"
                  tabletWidth="50%"
                  mobileWidth="90%"
                  margin="auto"
                  zIndex={2}
                  alignItems="center"
                >
                  <H2 fontWeight="bold" color="white" margin={!isLaptopWidth ? "0 0 20px 0" : "0 0 140px 0"}>
                    {category.name}
                  </H2>
                  {category.description && (
                    <P color="white" textAlign="center" margin="0 0 30px 0">
                      {category.description}
                    </P>
                  )}
                  <Button
                    text="Learn"
                    width="max-content"
                    theme="blockWhite"
                    linkTo={`/learn/${category.slug?.current}`}
                  />
                </Container>
                <Image
                  minHeight={isMobileWidth ? "300px" : "500px"}
                  width="100%"
                  isBackground
                  image={category.image?.asset?.gatsbyImageData}
                />
                <Gradient gradient={gradients.gradientFromTop} />
              </StyledContainer>
            </LinkContainer>
          );
        })}
      </Container>
    </Container>
  );
}

export default LearnCategoryPreview;

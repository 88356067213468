import React from "react";
import styled from "styled-components";

import { SanityCtaBlock } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent } from "@components/global";
import Cta from "./cta";
import { colors, fontWeights, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityCtaBlock;
  margin?: string;
}

const TextContainer = styled(Container)`
  width: 55%;
  text-align: center;
  margin: auto;
  h1,
  h2,
  h3,
  p {
    font-weight: ${fontWeights.normal};
    color: ${colors.darkerGreen};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

function CtaBlock({ data, margin }: Props) {
  if (data == null) {
    return null;
  }

  return (
    <Container flexDirection="column" justifyContent="center" margin={margin ? margin : "100px 0"}>
      <TextContainer>
        <BlocksContent blocks={data.textBlock?._rawColumnContent} />
      </TextContainer>
      {data.ctas && (
        <Container justifyContent="center" margin="30px 0 0 0">
          {data.ctas.map(cta => {
            if (cta == null) {
              return null;
            }

            return <Cta whiteBackground data={cta} key={cta._key} />;
          })}
        </Container>
      )}
    </Container>
  );
}

export default CtaBlock;

import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import Hero from "@components/global/hero";
import SEO from "@shared/seo";
import ColumnContent from "@components/global/columnContent";
import CtaBlock from "@components/global/ctaBlock";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import {
  isSanityLearnCategoryPreview,
  isSanityColumnContent,
  isSanityCtaBlock,
  isSanityCtaBanner,
  isSanityProfilesBlock,
} from "@util/types";
import CtaBanner from "@components/global/ctaBanner";
import ProfileBlock from "@components/about/profileBlock";
import { useStore } from "@state/store";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function About({ data }: Props) {
  if (data == null || data.sanityAbout == null) {
    return null;
  }

  const { hero, seo, pageContent } = data.sanityAbout;

  useEffect(() => {
    useStore.setState({ searchFilters: [] });
  });

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {hero && <Hero data={hero} />}
        {pageContent &&
          pageContent.map(data => {
            if (isSanityLearnCategoryPreview(data)) {
              return <LearnCategoryPreview data={data} key={data._key} />;
            }
            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} boldHeaders />;
            }
            if (isSanityCtaBlock(data)) {
              return <CtaBlock data={data} key={data._key} />;
            }
            if (isSanityCtaBanner(data)) {
              return <CtaBanner data={data} key={data._key} />;
            }
            if (isSanityProfilesBlock(data)) {
              return <ProfileBlock data={data} />;
            }
            return null;
          })}
      </Layout>
    </>
  );
}

export default About;

export const query = graphql`
  {
    sanityAbout {
      hero {
        ...sanityHero
      }
      seo {
        ...sanitySeo
      }
      pageContent {
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
        ... on SanityProfilesBlock {
          ...sanityProfilesBlock
        }
        ... on SanityLearnCategoryPreview {
          ...sanityLearnCategoryPreview
        }
        ... on SanityCtaBlock {
          ...sanityCtaBlock
        }
        ... on SanityCtaBanner {
          _key
          _type
          ctas {
            ...sanityCta
          }
          bannerText {
            ...sanityColumn
          }
        }
      }
    }
  }
`;

import { BlocksContent, Image } from "@components/global";
import { SanityProfile, SanityProfilesBlock } from "@graphql-types";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  data: SanityProfilesBlock;
}

interface ProfileProps {
  profile: SanityProfile;
  index: number;
}

const Gradient = styled(Container)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29.88%, rgba(0, 0, 0, 0.7) 100%);
`;

const TextContainer = styled(Container)<{ hoverOn?: boolean }>`
  width: 68%;
  margin: 0 0 8% 5%;
  z-index: 2;

  p {
    ${({ hoverOn }) => (hoverOn ? `height: 100% margin: 0;` : `height: 0px; margin: 0;`)};
    transition: all 0.2s;
  }

  overflow: hidden;
  h1,
  h2,
  h3,
  h4 {
    width: 60%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 87%;
    margin: 60px auto;

    h1,
    h2,
    h3,
    h4 {
      color: ${colors.darkerGreen};
    }

    p {
      height: 100%;
      margin: 15px 0;
    }
  }
`;

function ProfileBlock({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isLaptopWidth } = useCheckScreenWidth();

  const Profile = ({ profile, index }: ProfileProps) => {
    const [hoverOn, setHoverOn] = useState(false);

    return (
      <Container
        width="50%"
        tabletWidth="100%"
        flexDirection="column"
        margin={(index + 1) % 2 === 0 ? "0 0 15px 7px" : "0 7px 15px 0"}
        tabletMargin="auto"
      >
        <Container
          onMouseEnter={() => setHoverOn(true)}
          onMouseLeave={() => setHoverOn(false)}
          backgroundColor={profile.profileImage ? "transparent" : "mossGreen"}
          position="relative"
          width="100%"
          tabletWidth="92%"
          height="500px"
          tabletHeight="300px"
          minHeight="375px"
          flexDirection="column"
          justifyContent="flex-end"
          tabletMargin="auto"
        >
          {profile.profileImage && (
            <Image objectFit="cover" width="100%" isBackground image={profile.profileImage.asset?.gatsbyImageData} />
          )}
          <Gradient />
          {isLaptopWidth && (
            <TextContainer hoverOn={hoverOn}>
              <BlocksContent whiteText={isLaptopWidth} blocks={profile.profileText?._rawColumnContent} />
            </TextContainer>
          )}
        </Container>
        {!isLaptopWidth && (
          <TextContainer zIndex={2}>
            <BlocksContent whiteText={isLaptopWidth} blocks={profile.profileText?._rawColumnContent} />
          </TextContainer>
        )}
      </Container>
    );
  };

  return (
    <Container width="98%" margin="15px auto" tabletWidth="100%" flexDirection={!isLaptopWidth ? "column" : "row"}>
      {data.profiles?.map((profile, index) => {
        if (profile == null) {
          return null;
        }

        return <Profile profile={profile} index={index} />;
      })}
    </Container>
  );
}

export default ProfileBlock;
